import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';

import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import HomeContainer from './components/HomeContainer';
import Test from './components/Test';
import Forbidden from './components/Forbidden';

import TestHr from './components/hr/TestHr';
import ProtectedRouteByRole from './components/hr/ProtectedRouteByRole';
import HrContainer from './components/hr/HrContainer';


function App() {
    return (
        <div className="App">
            <Switch>
                <Route exact path='/' component={Login} />
                <Route path='/login' component={Login} />
                <Route path='/register' component={Register} />

                <Route path='/test' component={Test} />

                {/* <Route path='/home' component={Home}/>
                <Route path='/report-activity/:id' component={ReportActivity}/> */}

                

                <Route path='/forbidden' component={withRouter(Forbidden)} />

                <ProtectedRoute path='/home' component={withRouter(HomeContainer)} />


                <ProtectedRouteByRole path='/hr' component={withRouter(HrContainer)}/>

            </Switch>
        </div>
    );
}

export default App;
