import React, { Component } from 'react';
import { Button, Row, Space, Spin, Divider } from 'antd';
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, FieldTimeOutlined, UserAddOutlined } from '@ant-design/icons';
import AuthService from '../services/auth.service';
import EmpService from '../services/employee.service';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            empCode: AuthService.getCurrentUserId(),
            
            loading: true,
            acts: []
        };

        this.onSubmitAct = this.onSubmitAct.bind(this);
        this.onGetCurrentActs = this.onGetCurrentActs.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true,
            acts: []
        });

        this.onGetCurrentActs();
    }

    onGetCurrentActs() {
        const { empCode } = this.state;

        EmpService.getCurrentActs(empCode)
            .then(res => {
                this.setState({
                    acts: res.data,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
            });
    }

    onSubmitAct(ev) {
        const { empCode } = this.state;

        this.setState({
            loading: true
        });

        setTimeout(() => {
            EmpService
                .submitActs(empCode, ev)
                .then(res => this.onGetCurrentActs())
                .catch(err => this.setState({ loading: false }));
        }, 1000);
    }

    render() {
        const { acts } = this.state;

        return (
            <Row className="Home-Content-Container">
                <Spin spinning={this.state.loading} delay={500}>

                    <Space direction="vertical">
                        <Button block type="primary" shape="round" icon={<UserAddOutlined />} size='large'
                            onClick={() => this.onSubmitAct(1)}
                            disabled={acts ? (acts.filter(e => e.ev == 1)).length > 0 : false}>เริ่มการทำงาน</Button>

                        <Divider />

                        <Link to={{
                            pathname: '/submit-activities',
                            state: {
                                title: '09:00-11:00',
                                ev: 3,
                                tmslot: 1
                            }
                        }}>
                            <Button block type="primary" shape="round" icon={<FieldTimeOutlined />} size='large'
                                disabled={acts ? (acts.filter(e => e.ev == 3 && e.tmslot == 1)).length > 0 : false}>
                                เวลา 09:00-11:00</Button>
                        </Link>

                        {/* <Button block type="primary" shape="round" icon={<DownloadOutlined />} size='large'
                            disabled={acts ? (acts.filter(e => e.ev == 3 && e.tmslot == 1)).length > 0 : false}>
                            <Space size="small" align="baseline">
                                <Link to={{
                                    pathname: '/submit-activities',
                                    state: {
                                        title: '09:00-11:00',
                                        ev: 3,
                                        tmslot: 1
                                    }
                                }}></Link>
                            เวลา 09:00-11:00
                            </Space>
                        </Button> */}

                        <Link to={{
                            pathname: '/submit-activities',
                            state: {
                                title: '11:00-14:00',
                                ev: 3,
                                tmslot: 2
                            }
                        }}>
                            <Button block type="primary" shape="round" icon={<FieldTimeOutlined />} size='large'
                                disabled={acts ? (acts.filter(e => e.ev == 3 && e.tmslot == 2)).length > 0 : false}>
                                เวลา 11:00-14:00
                        </Button>
                        </Link>

                        <Link to={{
                            pathname: '/submit-activities',
                            state: {
                                title: '14:00-17:00',
                                ev: 3,
                                tmslot: 3
                            }
                        }}>
                            <Button block type="primary" shape="round" icon={<FieldTimeOutlined />} size='large'
                                disabled={acts ? (acts.filter(e => e.ev == 3 && e.tmslot == 3)).length > 0 : false}>
                                เวลา 14:00-17:00
                        </Button>
                        </Link>

                        <Divider />

                        <Button block type="primary" shape="round" icon={<ArrowLeftOutlined />} size='large'
                            onClick={() => this.onSubmitAct(2)} danger
                            disabled={acts ? (acts.filter(e => e.ev == 2)).length > 0 : false}>สิ้นสุดการทำงาน</Button>
                        <Divider />

                        
                    </Space>

                </Spin>

            </Row>


        );
    }
}