import React, { Component } from 'react';
import { Select, Space, Tooltip, Typography, Layout, Menu, Button } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, FieldTimeOutlined } from '@ant-design/icons';

import SearchService from '../../services/search.service';

import '../../AppHr.css';
import { BrowserRouter, Switch, Route, NavLink, withRouter, Link } from 'react-router-dom';
import HrDataManagement from './HrDataManagement';
import PropTypes from 'prop-types';
import TestHr from './TestHr';

const { Option } = Select;
const { Text, Paragraph } = Typography;
const { Header, Content, Footer, Sider } = Layout;


export default class HrContainer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }


    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {
        const { location } = this.props;

        return (
            <Layout className="Hr-Layout">
                <Link to={{
                    pathname: '/hr/data/',
                    state: {
                        title: '09:00-11:00',
                        ev: 3,
                        tmslot: 1
                    }
                }}>
                    <Button block type="primary" shape="round" icon={<FieldTimeOutlined />} size='large'>
                        เวลา 09:00-11:00</Button>
                </Link>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['/hr']} selectedKeys={[location.pathname]}>
                        <Menu.Item key="/hr" icon={<UserOutlined />}>
                            <NavLink to='/hr'>
                                <span>Home</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<VideoCameraOutlined />}>
                            Data
                </Menu.Item>
                        <Menu.Item key="3" icon={<UploadOutlined />}>
                            nav 3
                </Menu.Item>
                        <Menu.Item key='/hr/test' icon={<UserOutlined />}>
                            <NavLink to='/hr/test'>
                                <span>test</span>
                            </NavLink>

                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '24px 16px 0' }}>
                        {/* <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            content
                </div> */}

                        <BrowserRouter basename='/hr'>
                            <Switch>
                                {/* <Route path='/submit-activities' component={ReportActivity} />
                                <Route path='/test' component={Test} /> */}

                                <Route key="1" exact path='/' component={TestHr} />
                                <Route key="2" path='/data' component={HrDataManagement} />

                            </Switch>
                        </BrowserRouter>

                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        );
    }
}