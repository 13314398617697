import React from 'react'
import { Redirect } from 'react-router-dom'
import AuthService from '../../services/auth.service';

class ProtectedRouteByRole extends React.Component {
    render() {
        const Component = this.props.component;
        const isAuthenticated = true;// AuthService.getCurrentUserRoles().includes('hr');

        return isAuthenticated ? (
            <Component {... this.props} />
        ) : (
                // <Redirect to={this.props.location.goBack} />
                <Redirect to="/forbidden" />
            );
    }
}

export default ProtectedRouteByRole;