import axios from 'axios';
import { API_URL } from '../config';

class AuthService {
    getBasicConfig() {
        return axios
            .get(API_URL + 'auth/app/conf')
            .then(res => {
                return res.data; // callback_uri
            });
    }

    register(accId, code, state, emp_code, display_name) {
        return axios
            .post(API_URL + 'auth/register', { acc_id: accId, line: { code, state }, register: { emp_code, display_name } })
            .then(res => {
                return res.data;
            });
    }

    loginByLine(code, state) {
        return axios
            .post(API_URL + 'auth/signin-line', { code, state })
            .then(res => {
                if (res.data.code == 0) throw res.data.code_error;
                const data = res.data.data;
                if (data.access_token) {
                    localStorage.setItem('user', JSON.stringify(data))
                }
                return data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getCurrentUserId() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.emp?.emp_code : null;
    }

    getCurrentUserRoles() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.emp?.roles : [];
    }
}

export default new AuthService();
