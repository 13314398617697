import React, { Component } from 'react';
import { Select, Space, Tooltip, Typography, Layout, Menu, Tabs } from 'antd';
import { UploadOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons';

import SearchService from '../../services/search.service';

const { Option } = Select;
const { Text, Paragraph } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;


export default class HrDataManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {
        return (
            <Tabs defaultActiveKey="2">
                <TabPane
                    tab={
                        <span>
                            <AppleOutlined />
          Tab 1
        </span>
                    }
                    key="1"
                >
                    Tab 1
    </TabPane>
                <TabPane
                    tab={
                        <span>
                            <AndroidOutlined />
          Tab 2
        </span>
                    }
                    key="2"
                >
                    Tab 2
    </TabPane>
            </Tabs>
        );
    }
}