import React, { Component } from 'react';
import { Select, Space, Tooltip, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import SearchService from '../../services/search.service';

const { Option } = Select;
const { Text, Paragraph } = Typography;

export default class TestHr extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {
        

        return (
            <h1>HR</h1>
        );
    }
}