import React, { Component } from 'react';
import { Select, Space, Button, Tooltip, Typography, Result } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import SearchService from '../services/search.service';

const { Option } = Select;
const { Text, Paragraph } = Typography;

export default class Forbidden extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {
        return (
            <Result
                status="error"
                title="Forbidden"
                subTitle="The content"
                extra={<Button onClick={()=>this.props.history.goBack()}>Back</Button>}
            >
                <div className="desc">
                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 16,
                            }}>
                            The content you submitted has the following error:
        </Text>
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been
        frozen. <a>Thaw immediately &gt;</a>
                    </Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet
        eligible to apply. <a>Apply Unlock &gt;</a>
                    </Paragraph>
                </div>
            </Result>
        );
    }
}